import { createSlice } from "@reduxjs/toolkit";
import { AppApiToken } from "../../api/AppApi";

const initialState = {
  isLoading: false,
  isError: false,
  message: null,
  complaintBook: [],
  complaintsBooks: [],
  complaintsBooksAll: [],
  pagination: {
    total: 0,
    currentPage: 0,
    pageSize: 0,
    lastPage: 0,
  },
};

const complaintsBookSlice = createSlice({
  name: "complaintsBook",
  initialState,
  reducers: {
    // START LOADING
    newRequest(state) {
      state.isLoading = true;
      state.isError = false;
      state.message = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload;
    },

    dataComplaintsBookSuccess(state, action) {
      const pagination = action.payload.meta;
      state.isError = false;
      state.isLoading = false;
      state.message = "Obtuvimos todos las reclamaciones🙌";
      state.complaintsBooks = action.payload.data;
      // Paginacion
      state.pagination.currentPage = pagination.current_page;
      state.pagination.pageSize = pagination.per_page * 1;
      state.pagination.total = pagination.total;
      state.pagination.lastPage = pagination.last_page;
    },
    exportComplaintsBookSuccess(state, action) {
      state.isError = false;
      state.isLoading = false;
      state.message = "Exportamos todas las reclmaciones 🙌";
      state.complaintsBooksAll = action.payload.data;
    },
  },
});

// Reducer - Aca va la data o los valores
export default complaintsBookSlice.reducer;

// Actions - pasa la accion(un  grupo de data) para cambiar el state
export const {
  newRequest,
  hasError,
  dataComplaintsBookSuccess,
  exportComplaintsBookSuccess,
} = complaintsBookSlice.actions;

// ========Funciones===========
// -------------------------------------------------------

// Data de  data de libro de reclamaciones
// -------------------------------------------------------
export function dataComplaintsBook(query) {
  const { is_paginate = true, page, search } = query;
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(
        `complaint-books/records?is_paginate=${is_paginate}&per_page=15&page=${page}&search=${search}`
      );
      dispatch(dataComplaintsBookSuccess(response?.data));
      return response?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}

// Para exportar data de libro de reclamaciones
// -------------------------------------------------------
export function exportComplaintsBook() {
  return async (dispatch) => {
    dispatch(newRequest());
    try {
      const response = await AppApiToken.get(`complaint-books/records`);
      dispatch(exportComplaintsBookSuccess(response?.data));
      return response?.data?.data;
    } catch (error) {
      console.error("Error en consumir: ", error);
      dispatch(hasError(error.response?.data.message));
    }
  };
}
