import { useEffect, useState } from "react";
import {
  Button,
  Container,
  InputAdornment,
  Pagination,
  Paper,
  Stack,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import CsvDownloadButton from "react-json-to-csv";
import {
  FilePresentOutlined,
  InfoRounded,
  SearchOutlined,
} from "@mui/icons-material";
import HeaderBreadcrumbs from "../../../components/HeaderBreadcrumbs";

import TableGeneral from "../../../components/InitTables/TableGeneral";
import Page from "../../../components/Page";
// Redux
import { useDispatch, useSelector } from "react-redux";

import {
  dataComplaintsBook,
  exportComplaintsBook,
} from "../../../redux/slices/complaintsBook";

const LibroReclamaciones = () => {
  const dispatch = useDispatch();

  const { complaintsBooks, pagination, isLoading } = useSelector(
    (state) => state.complaintsBook
  );
  const { total, currentPage, lastPage } = pagination;
  const [allDataSubs, setAllDataSubs] = useState([]);

  // Paginacion
  const handleChangePagination = (event, value) => {
    dispatch(dataComplaintsBook({ page: value, search: "" }));
  };

  // Buscador
  const handleChangeSearch = async (event) => {
    event.preventDefault();
    dispatch(
      dataComplaintsBook({
        page: 1,
        search: event.target.value,
      })
    );
  };

  const tableHeaders = [
    { id: "id", label: "N° Reclamo" },
    { id: "created_at", label: "Fecha de reclamo" },
    { id: "company_name", label: "Compañia", align: "left" },
    { id: "document_number", label: "Documento", align: "left" },
    { id: "full_name", label: "Nombres", align: "left", width: 700 },
    { id: "email", label: "Correo", align: "left" },
    { id: "phone", label: "Celular", align: "left" },
    {
      id: "legal_rights",
      label: "Información completa",
      align: "center",
    },
    { id: "client_has_notification", label: "Notif. cliente", align: "center" },
    {
      id: "company_has_notification",
      label: "Notif. compañia",
      align: "center",
    },

    {
      id: "path",
      label: "Descargar PDF",
      align: "center",
    },
  ];

  const DATA_CLIENTS = complaintsBooks?.map((item, index) => ({
    // id: item?.id.toString().padStart(6, "0"),
    id: item?.series,
    created_at: new Date(item?.created_at).toLocaleDateString("es-PE"),
    company_name: item?.company_name,
    email: item?.email,
    document_number: `${item?.document_type === "1" ? "DNI" : "CE"}  ${
      item?.document_number
    }`,
    phone: item?.phone,
    full_name: item?.full_name,
    client_has_notification: item?.client_has_notification ? "Si" : "No",
    company_has_notification: item?.company_has_notification ? "Si" : "No",
    legal_rights: (
      <Tooltip
        title={
          <Stack>
            <Typography variant="caption">
              <strong>IDENTIFICACIÓN DEL CONSUMIDOR RECLAMANTE:</strong>
            </Typography>
            <Typography variant="caption">
              <strong>Dirección:</strong> {item?.address}
            </Typography>
            <Typography variant="caption">
              <strong>¿Es menor de edad?:</strong>{" "}
              {item?.is_minor ? "Si" : "No"}
            </Typography>
            {item?.is_minor && (
              <Typography variant="caption">
                <strong>Apoderado:</strong> {item?.proxy}
              </Typography>
            )}
            <Typography variant="caption">
              <strong>IDENTIFICACIÓN DEL BIEN CONTRATADO:</strong>
            </Typography>
            <Typography variant="caption">
              <strong>Tipo de contrato:</strong> {item?.desc_type_contract}
            </Typography>
            <Typography variant="caption">
              <strong>Detalle del contrato:</strong> {item?.detail_contract}
            </Typography>
            <Typography variant="caption">
              <strong>Monto:</strong> {item?.currency} {item?.amount}{" "}
              {item?.currency_name}
            </Typography>
            <Typography variant="caption">
              <strong>DETALLE DE LA RECLAMACIÓN:</strong>
            </Typography>
            <Typography variant="caption">
              <strong>Tipo de reclamo:</strong> {item?.desc_type_claim}
            </Typography>
            <Typography variant="caption">
              <strong>Detalle del reclamo:</strong> {item?.detail_claim}
            </Typography>
            <Typography variant="caption">
              <strong>Pedido del reclamo:</strong> {item?.claim_request}
            </Typography>
          </Stack>
        }
      >
        <IconButton>
          <InfoRounded />
        </IconButton>
      </Tooltip>
    ),
    path: (
      <>
        <a href={item?.path} target="_blank" rel="noreferrer">
          <FilePresentOutlined />
        </a>
      </>
    ),
  }));

  // Obtener suscriptores
  useEffect(() => {
    dispatch(dataComplaintsBook({ page: 1, search: "" }));
  }, [dispatch]);

  const handleDowloandCsv = async () => {
    const data = await dispatch(exportComplaintsBook());
    setAllDataSubs(data);
  };

  return (
    <Page title="Administración: Libro de Reclamaciones">
      <Container maxWidth="lg">
        <HeaderBreadcrumbs
          heading="Lista de Libro de Reclamaciones"
          links={[
            { name: "Inicio", href: "/" },
            { name: "Libro de Reclamaciones" },
          ]}
          action={
            <>
              {allDataSubs.length < 1 ? (
                <Button
                  variant="contained"
                  onClick={handleDowloandCsv}
                  disabled={isLoading}
                >
                  Generar reporte
                </Button>
              ) : (
                <CsvDownloadButton
                  data={allDataSubs}
                  filename="derechosarco-incamotors.csv"
                  style={{
                    //pass other props, like styles
                    boxShadow: "inset 0px 1px 0px 0px #287233",
                    backgroundColor: "#287233",
                    borderRadius: "6px",
                    border: "1px solid #287233",
                    display: "inline-block",
                    cursor: "pointer",
                    color: "#ffffff",
                    fontSize: "15px",
                    fontWeight: "bold",
                    padding: "6px 24px",
                    textDecoration: "none",
                    textShadow: "0px 1px 0px #287233",
                  }}
                >
                  Descargar reporte
                </CsvDownloadButton>
              )}
            </>
          }
        />
        {/* Tabla */}

        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: 630,
            justifyContent: "space-between",
          }}
        >
          <Stack>
            <Stack direction="row" justifyContent="flex-end" sx={{ p: 1 }}>
              <TextField
                onChange={handleChangeSearch}
                variant="outlined"
                placeholder="Buscar..."
                sx={{ m: 1, width: "40ch" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <TableGeneral
              rows={DATA_CLIENTS}
              headLabel={tableHeaders}
              isLoading={isLoading}
            />
          </Stack>

          <Stack
            direction="row"
            sx={{ p: 1, textAlign: "center", justifyContent: "flex-end" }}
          >
            <Typography variant="caption" sx={{ marginTop: "8px" }}>
              TOTAL: {total}
            </Typography>

            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handleChangePagination}
              showFirstButton
              showLastButton
            />
          </Stack>
        </Paper>
      </Container>
    </Page>
  );
};

export default LibroReclamaciones;
